<template>
   <v-layout align-start>
      <v-flex xs12>
         <v-snackbar
            v-model="snackbar"
            :color="snackColor"
            :right="true"
            :bottom="true"
            :timeout="4000"
         >
            {{ snackText }}
            <template #action="{ attrs }">
               <v-btn
                  color="white"
                  text
                  v-bind="attrs"
                  @click="snackbar = false"
               >
                  Cerrar
               </v-btn>
            </template>
         </v-snackbar>
         <!-- DIALOG INFO -->
         <v-dialog v-model="dialogInformacion" max-width="550px" persistent>
            <v-form
               ref="formRegistro"
               v-model="validRegistro"
               lazy-validation
               mt-12
            >
               <v-card>
                  <v-toolbar color="primary" dark>
                     <v-toolbar-title style="font-weight: 500">
                        <span> Información de cliente externo </span>
                     </v-toolbar-title>
                     <v-spacer></v-spacer>
                     <v-spacer></v-spacer>
                  </v-toolbar>
                  <v-card-text class="text--primary">
                     <v-container grid-list-md>
                        <v-layout wrap>
                           <v-flex xs12 sm6>
                              <v-text-field
                                 v-model="nombres"
                                 :rules="nombresRules"
                                 color="primary"
                                 label="Nombres"
                                 readonly
                              ></v-text-field>
                           </v-flex>
                           <v-flex xs12 sm6>
                              <v-text-field
                                 v-model="apellidos"
                                 :rules="apellidosRules"
                                 color="primary"
                                 label="Apellidos"
                                 readonly
                              ></v-text-field>
                           </v-flex>
                           <v-flex xs12 sm6>
                              <v-text-field
                                 v-model="dui"
                                 v-mask="'########-#'"
                                 :rules="duiRules"
                                 color="primary"
                                 label="DUI"
                                 readonly
                              ></v-text-field>
                           </v-flex>
                           <v-flex xs12 sm6>
                              <v-text-field
                                 v-model="nit"
                                 v-mask="'####-######-###-#'"
                                 :rules="nitRules"
                                 color="primary"
                                 label="NIT"
                                 readonly
                              ></v-text-field>
                           </v-flex>
                           <v-flex xs12 sm6>
                              <v-text-field
                                 v-model="email"
                                 :rules="emailRules"
                                 color="primary"
                                 label="Email"
                                 readonly
                              ></v-text-field>
                           </v-flex>
                           <v-flex xs12 sm6>
                              <v-text-field
                                 v-model="telefono"
                                 v-mask="'####-####'"
                                 :rules="telefonoRules"
                                 color="primary"
                                 label="Teléfono"
                                 readonly
                              ></v-text-field>
                           </v-flex>
                           <v-flex xs12 sm12>
                              <v-text-field
                                 v-model="direccion"
                                 :rules="direccionRules"
                                 color="primary"
                                 label="Dirección"
                                 readonly
                              ></v-text-field>
                           </v-flex>
                           <v-flex xs12>
                              <v-layout justify-left align-center>
                                 <v-checkbox
                                    v-model="checkExtranjero"
                                    label="Es extranjero"
                                    color="primary"
                                    readonly
                                 ></v-checkbox>
                              </v-layout>
                           </v-flex>
                        </v-layout>
                     </v-container>
                  </v-card-text>

                  <v-card-actions
                     style="
                        margin-top: -40px;
                        margin-right: 20px;
                        padding-bottom: 20px;
                     "
                  >
                     <v-spacer></v-spacer>
                     <v-btn
                        color="primary white--text"
                        @click.native="dialogInformacion = false"
                     >
                        Salir
                     </v-btn>
                  </v-card-actions>
               </v-card>
            </v-form>
         </v-dialog>

         <!-- DIALOG ELIMINAR -->
         <v-dialog v-model="dialogEliminar" persistent max-width="600px">
            <v-form ref="formEliminar">
               <v-card>
                  <v-card-text class="text--primary" style="margin-top: -20px">
                     <v-container grid-list-md>
                        <v-layout wrap>
                           <v-flex xs12>
                              <span class="text-h5 font-weight-medium"
                                 >Remover cliente externo</span
                              >
                           </v-flex>
                           <v-flex xs12>
                              <span
                                 class="font-weight-regular"
                                 style="font-size: 20px"
                                 >¿Esta seguro que desea remover el
                                 arancel?</span
                              >
                              <v-layout wrap style="margin-top: 10px">
                                 <v-flex xs12>
                                    <div style="font-size: 18px">Carnet:</div>
                                    <v-card
                                       flat
                                       color="red lighten-5"
                                       class="text-center elevation-1"
                                       style="border-radius: 10px"
                                    >
                                       <v-card-text>
                                          <span
                                             class="red--text text--darken-2 text-h6"
                                             >{{ carnet }}</span
                                          >
                                       </v-card-text>
                                    </v-card>
                                 </v-flex>
                                 <v-flex xs12 sm6>
                                    <div style="font-size: 18px">Nombres:</div>
                                    <v-card
                                       flat
                                       color="red lighten-5"
                                       class="text-center elevation-1"
                                       style="border-radius: 10px"
                                    >
                                       <v-card-text>
                                          <span
                                             class="red--text text--darken-2 text-h6"
                                             >{{ nombres }}</span
                                          >
                                       </v-card-text>
                                    </v-card>
                                 </v-flex>
                                 <v-flex xs12 sm6>
                                    <div style="font-size: 18px">
                                       Apellidos:
                                    </div>
                                    <v-card
                                       flat
                                       color="red lighten-5"
                                       class="text-center elevation-1"
                                       style="border-radius: 10px"
                                    >
                                       <v-card-text>
                                          <span
                                             class="red--text text--darken-2 text-h6"
                                             >{{ apellidos }}</span
                                          >
                                       </v-card-text>
                                    </v-card>
                                 </v-flex>
                              </v-layout>
                           </v-flex>
                           <v-flex xs12 text-center>
                              <v-icon
                                 color="primary"
                                 size="70"
                                 class="mt-2 mb-2"
                                 >fa-trash-alt</v-icon
                              >
                           </v-flex>
                        </v-layout>
                     </v-container>
                  </v-card-text>

                  <v-card-actions
                     style="margin-top: -30px; padding-bottom: 20px"
                  >
                     <v-spacer></v-spacer>
                     <v-btn
                        color="primary"
                        dark
                        outlined
                        min-width="150"
                        @click.native="close"
                        >No</v-btn
                     >
                     <v-btn
                        color="primary"
                        dark
                        min-width="150"
                        @click.native="removerCliente"
                        >Si, eliminar</v-btn
                     >
                     <v-spacer></v-spacer>
                  </v-card-actions>
               </v-card>
            </v-form>
         </v-dialog>

         <v-card elevation="0">
            <v-toolbar color="primary" dark>
               <v-toolbar-title style="font-weight: 500"
                  >Clientes externos</v-toolbar-title
               >
               <div class="mt-6 ml-3" style="width: 200px">
                  <v-autocomplete
                     v-model="selectTipoCliente"
                     :items="cmbTipoCliente"
                     color="white"
                     item-text="descripcion"
                     item-value="id"
                     label="Tipo Cliente"
                     no-data-text="Sin resultados"
                     placeholder="Seleccionar..."
                  >
                  </v-autocomplete>
               </div>
               <div class="mt-6 ml-3" style="width: 100px">
                  <v-autocomplete
                     v-model="selectEstadoInfo"
                     :items="cmbEstadoInfo"
                     color="white"
                     item-text="descripcion"
                     item-value="id"
                     label="Estado"
                     no-data-text="Sin resultados"
                     placeholder="Seleccionar..."
                  >
                  </v-autocomplete>
               </div>
               <v-spacer></v-spacer>
               <v-text-field
                  ref="txtBusqueda"
                  v-model="search"
                  class="mt-6"
                  color="white"
                  append-icon="fa-search"
                  label="Búsqueda"
                  single-line
               ></v-text-field>
               <v-spacer></v-spacer>
            </v-toolbar>

            <!-- DATATABLE becas -->
            <v-data-table
               :headers="headers"
               :items="filteredClientes"
               :loading="loadingClientes"
               loading-text="Listando clientes, por favor espere..."
               no-data-text="No se encontraron clientes."
               multi-sort
               :search="search"
               :footer-props="{
                  itemsPerPageOptions: [10, 25, 50],
                  itemsPerPageText: 'Filas por página:'
               }"
               no-results-text="Búsqueda sin resultados"
               class="elevation-1"
            >
               <template #footer="{}">
                  <tr>
                     <td :colspan="headers.length">
                        <v-layout align-center justify-start fill-height>
                           <div class="mx-4">
                              <span class="text-body-1 font-weight-medium">
                                 Buscar por
                              </span>
                           </div>
                           <div style="margin-top: 5px">
                              <v-radio-group v-model="radiosBusqueda" row>
                                 <v-radio
                                    label="Carnet"
                                    color="primary"
                                    value="r-carnet"
                                 ></v-radio>
                                 <v-radio
                                    label="Nombre"
                                    color="primary"
                                    value="r-nombre"
                                 ></v-radio>
                                 <v-radio
                                    label="Apellido"
                                    color="primary"
                                    value="r-apellido"
                                 ></v-radio>
                                 <v-radio
                                    label="Email"
                                    color="primary"
                                    value="r-email"
                                 ></v-radio>
                                 <v-radio
                                    label="DUI"
                                    color="primary"
                                    value="r-dui"
                                 ></v-radio>
                                 <v-radio
                                    label="NIT"
                                    color="primary"
                                    value="r-nit"
                                 ></v-radio>
                              </v-radio-group>
                           </div>
                        </v-layout>
                     </td>
                  </tr>
               </template>
               <template #item.opciones="{ item }">
                  <v-tooltip bottom color="primary">
                     <template #activator="{ on }">
                        <v-btn
                           text
                           small
                           fab
                           color="amber darken-3"
                           v-on="on"
                           @click="showInfoItem(item)"
                        >
                           <v-icon>fas fa-address-book</v-icon>
                        </v-btn>
                     </template>
                     <span>Informacion</span>
                  </v-tooltip>
                  <v-tooltip bottom color="primary">
                     <template #activator="{ on }">
                        <v-btn
                           text
                           small
                           fab
                           color="orange darken-3"
                           v-on="on"
                           @click="redirect(item)"
                        >
                           <v-icon>fas fa-external-link-alt</v-icon>
                        </v-btn>
                     </template>
                     <span>Colecturia Externa</span>
                  </v-tooltip>
                  <v-tooltip bottom color="primary">
                     <template #activator="{ on }">
                        <v-btn
                           text
                           small
                           fab
                           color="primary"
                           v-on="on"
                           @click="deleteItem(item)"
                        >
                           <v-icon>fas fa-trash-alt</v-icon>
                        </v-btn>
                     </template>
                     <span>Eliminar</span>
                  </v-tooltip>
               </template>

               <!-- Si no hay datos, mostrar boton para listar de nuevo -->
               <template slot="no-data">
                  <div v-if="becas.length == 0">
                     <v-container>
                        <v-layout wrap align-center>
                           <v-flex xs12 class="text-center">
                              <v-btn color="primary" @click="reset"
                                 >Resetear</v-btn
                              >
                           </v-flex>
                        </v-layout>
                     </v-container>
                  </div>
               </template>
            </v-data-table>
         </v-card>
      </v-flex>
   </v-layout>
</template>

<script>
import axios from 'axios'
import { authComputed } from '../helpers'
import { mask } from 'vue-the-mask'

export default {
   name: 'Clientes',
   directives: {
      mask
   },
   data: () => ({
      // Variables
      snackbar: false,
      snackText: '',
      snackColor: 'primary',
      validAdjunto: true,
      radiosBusqueda: 'r-nombre',
      dialogInformacion: false,
      dialogEnviandoComp: false,
      dialogEliminar: false,
      editedIndex: -1,
      validRegistro: true,
      headers: [
         { text: 'Carnet', value: 'carnet', class: 'titulo' },
         { text: 'Nombres', value: 'nombres', class: 'titulo' },
         { text: 'Apellidos', value: 'apellidos', class: 'titulo' },
         { text: 'Email', value: 'email', class: 'titulo' },
         {
            text: 'Dui',
            value: 'dui',
            class: 'titulo'
         },
         {
            text: 'Nit',
            value: 'nit',
            class: 'titulo'
         },
         {
            text: 'Opciones',
            value: 'opciones',
            align: 'right',
            sortable: false,
            class: 'titulo'
         }
      ],

      // Variables de formularios
      search: '',
      result: [],
      becas: [],
      cmbTipoCliente: [
         {
            id: 1,
            descripcion: 'Externos'
         },
         {
            id: 2,
            descripcion: 'Madre de la Iglesia'
         },
         {
            id: 3,
            descripcion: 'Idiomas'
         }
      ],
      cmbEstadoInfo: [
         {
            id: false,
            descripcion: 'Inactivo'
         },
         {
            id: true,
            descripcion: 'Activo'
         }
      ],
      selectEstadoInfo: true,
      selectTipoCliente: 1,
      loadingClientes: false,

      // Propiedades cliente

      carnet: '',
      checkExtranjero: false,
      nombres: '',
      apellidos: '',
      dui: '',
      nit: '',
      email: '',
      telefono: '',
      direccion: '',

      // Reglas de validacion de formularios
      nombresRules: [(v) => !!v || 'Ingrese sus nombres'],
      apellidosRules: [(v) => !!v || 'Ingrese sus apellidos'],
      carnetRules: [(v) => !!v || 'Ingrese su código de carnet'],
      duiRules: [
         (v) => !!v || 'Ingrese su DUI',
         (v) => (v && v.length == 10) || 'Termine de escribir su DUI'
      ],
      nitRules: [
         (v) => !!v || 'Ingrese su NIT',
         (v) => (v && v.length == 17) || 'Termine de escribir su NIT'
      ],
      telefonoRules: [
         (v) => !!v || 'Ingrese su número de teléfono',
         (v) => (v && v.length == 9) || 'Termine de escribir su teléfono'
      ],
      emailRules: [
         (v) => !!v || 'Ingrese su dirección e-mail',
         (v) =>
            v === '' ||
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
               v
            ) ||
            'Formato incorrecto de e-mail'
      ],
      direccionRules: [(v) => !!v || 'Ingrese una dirección']
   }),
   computed: {
      ...authComputed,
      // Titulo de dialog
      formTitle() {
         return this.editedIndex === -1
            ? 'Nueva beca externa'
            : 'Editar beca externa'
      },
      filteredClientes() {
         switch (this.radiosBusqueda) {
            case 'r-nombre':
               return this.becas.filter((i) => {
                  return (
                     !this.search ||
                     new RegExp(this.search, 'i').test(i.nombres)
                  )
               })
               break
            case 'r-carnet':
               return this.becas.filter((i) => {
                  return (
                     !this.search || new RegExp(this.search, 'i').test(i.carnet)
                  )
               })
               break
            case 'r-apellido':
               return this.becas.filter((i) => {
                  return (
                     !this.search ||
                     new RegExp(this.search, 'i').test(i.apellidos)
                  )
               })
               break
            case 'r-dui':
               return this.becas.filter((i) => {
                  return (
                     !this.search || new RegExp(this.search, 'i').test(i.dui)
                  )
               })
               break
            case 'r-nit':
               return this.becas.filter((i) => {
                  return (
                     !this.search || new RegExp(this.search, 'i').test(i.nit)
                  )
               })
               break
            case 'r-email':
               return this.becas.filter((i) => {
                  return (
                     !this.search || new RegExp(this.search, 'i').test(i.email)
                  )
               })
               break
            default:
               return this.becas
         }
      }
   },
   watch: {
      selectTipoCliente() {
         this.listarClientes()
      },
      selectEstadoInfo() {
         this.listarClientes()
      }
   },
   created() {
      //Validando acceso al componente
      if (this.permController(13, 'ver')) {
         this.listarClientes()
      } else {
         this.$router.push({ name: 'inicio' })
      }
   },
   methods: {
      removerCliente() {
         axios
            .delete('api/Cliente/' + this.carnet)
            .then(() => {
               this.listarClientes()
               this.close()
               this.snackText = 'Cliente removido exitosamente!'
               this.snackColor = 'snackSuccess'
               this.snackbar = true
            })
            .catch(() => {
               this.snackText = 'No fue posible eliminar el cliente externo'
               this.snackColor = 'snackError'
               this.snackbar = true
            })
      },
      deleteItem(item) {
         this.carnet = item.carnet
         this.nombres = item.nombres
         this.apellidos = item.apellidos
         this.dialogEliminar = true
      },
      // Autoseleccionar la busqueda al cambiar filtro de busquedad
      focusBusqueda() {
         this.$refs.txtBusqueda.focus()
      },
      // Popular el datatable
      listarClientes() {
         this.becas = []
         this.loadingClientes = true
         // Obteniendo becas
         axios
            .get(
               'api/Cliente?Tipo=' +
                  this.selectTipoCliente +
                  '&InfoValida=' +
                  this.selectEstadoInfo
            )
            .then((response) => {
               this.becas = response.data
               this.loadingClientes = false
            })
            .catch((error) => {
               console.log(error)
               this.loadingClientes = false
            })
      },
      // Si no cargaron bien las becas en el datatable se puede resetear
      reset() {
         this.listarClientes()
      },

      // Cargando informacion del item a editar
      showInfoItem(item) {
         // Obteniendo valores para bodega segun id
         axios
            .get('api/Cliente?Carnet=' + item.carnet)
            .then((response) => {
               this.result = response.data[0]
               this.carnet = this.result.carnet
               this.nombres = this.result.nombres
               this.apellidos = this.result.apellidos
               this.dui = this.result.dui
               this.nit = this.result.nit
               this.email = this.result.email
               this.telefono = this.result.telefono
               this.checkExtranjero = this.result.checkExtranjero
               this.direccion = this.result.direccion
            })
            .catch((error) => {
               console.log(error)
            })
            .finally(() => {
               this.editedIndex = 1
               this.dialogInformacion = true
            })
         if (document.activeElement instanceof HTMLElement)
            document.activeElement.blur()
      },

      // Cerrar ventana de dialogo
      close() {
         this.dialogInformacion = false
         this.dialogEliminar = false
         this.clear()
         setTimeout(() => {
            this.editedIndex = -1
         }, 300)
      },

      // Limpiar formularios y componentes
      clear() {
         try {
            this.$refs.formBecas.resetValidation()
         } catch (error) {
            //Do nothing
         }
         try {
            this.$refs.formAdjunto.resetValidation()
         } catch (error) {
            //Do nothing
         }
         this.result = ''
         this.carnet = ''
         this.ciclo = ''
         this.talonario = ''
         this.selectInstitucion = ''
         this.archivosAdjuntos = []
      },
      redirect(item) {
         this.$store.dispatch('login', item)
         let routeData = this.$router.resolve({ name: 'colecturiaExterna' })
         window.open(routeData.href, '_blank')
         if (document.activeElement instanceof HTMLElement)
            document.activeElement.blur()
      }
   }
}
</script>
